<div>
  <p class="font-medium text-darkblue text-xl">Recent Articles</p>
  <div class="row my-4" *ngFor="let item of lists">
    <div class="col-lg-5 col-md-8 col-5 mb-4">
      <img src="../../../assets/img/pub1.png" alt="" class="w-100" />
    </div>
    <div class="col-xl-7 col-lg-7 col-md-12 col-7 mb-4">
      <p class="text-grey mb-1 text-sm">June 14 2021</p>
      <p class="font-bold text-darkblue text-lg line-height-1 mb-2">Orphanage</p>
      <p class="text-sm mb-1">
        Anchor foundation propose to touch lives or more orphans
      </p>
      <div
        class="text-hibiscus font-medium click text-sm"
        routerLink="/article/:id"
      >
        Read More
      </div>
    </div>
  </div>
</div>
