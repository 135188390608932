<section class="wrapper">
  <section class="container">
    <app-navbar [darkMode]="true" class="my-5"></app-navbar>
    <app-sidebar [darkMode]="true"></app-sidebar>
    <section class="row py-5 top-space">
      <div class="col-lg-8 col-md-9 col-12 mb-4">
        <p class="text-lg text-hibiscus font-medium">June 14 2021</p>
        <p class="font-bold text-darkblue text-xl">
          How our foundation has touched lives.
        </p>
        <div class="d-flex align-items-center">
          <div class="profile-img me-2">
            <img src="../../../assets/img/profile.png" alt="" />
          </div>
          <div class="text-sm font-medium text-darkblue">By John kwell</div>
        </div>
        <img
          src="../../../assets/img/pub1.png"
          alt=""
          class="article-img my-4"
        />
        <p>
          It was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss. It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss. It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.
          <br /><br />

          It was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.
          <br /><br />

          It was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.
          <br /><br />

          It was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.
          <br /><br />

          It was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.
          <br /><br />
          It was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.It
          was and still the dream of Anchor foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.
        </p>
        <div class="d-flex align-items-center font-medium social-link">
          <span class="me-2">Share:</span>
          <a href="#" target="_blanc" class="text-2xl me-2">
            <span class="iconify" data-icon="jam:twitter-circle"></span>
          </a>
          <a href="#" target="_blanc" class="text-2xl me-2">
            <span class="iconify" data-icon="jam:linkedin-circle"></span>
          </a>
          <a href="#" target="_blanc" class="text-2xl me-2">
            <span class="iconify" data-icon="iconoir:telegram-circled"></span>
          </a>
          <a href="#" target="_blanc" class="text-2xl">
            <span class="iconify" data-icon="jam:facebook-circle"></span>
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-md-3 col-12 mb-4">
        <app-recent-article></app-recent-article>
        <app-popular-article></app-popular-article>
        <app-floating-arrow></app-floating-arrow>
      </div>
    </section>
  </section>
  <app-footer class="mt-5"></app-footer>
</section>
