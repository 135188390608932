<header>
  <div class="container">
    <app-navbar></app-navbar>
    <app-sidebar></app-sidebar>
    <div>
      <h2 class="font-bold">Our Publications</h2>
    </div>
  </div>
</header>
<app-floating-arrow></app-floating-arrow>
<section class="wrapper">
  <div class="container">
    <div class="row my-4">
      <div class="col-lg-6 col-md-6 col-12 mb-4">
        <img src="../../../assets/img/pub1.png" alt="" class="w-100" />
      </div>
      <div class="col-xl-4 col-lg-5 col-md-6 col-sm-11 col-12 mb-4">
        <p class="font-bold text-grey text-lg mb-1">June 14 2021</p>
        <p class="font-bold text-darkblue text-2xl line-height-1">
          How our foundation has touched lives
        </p>
        <p class="font-bold text-hibiscus text-lg">By Uju Okunowo</p>
        <p>
          It was and still the dream of Anchor Foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.
          <br /><br />

          It was and still the dream of Anchor Foundation to reach out and touch
          more lives, helping the helpless and giving hope to the hopelesss.
        </p>
        <button class="btn btn-md btn-aqua" routerLink="/article/:id">
          Read More
        </button>
      </div>
    </div>
    <div class="row my-4">
      <div
        class="col-lg-4 col-md-6 col-sm-11 col-12 mb-5"
        *ngFor="let item of lists"
      >
        <img src="../../../assets/img/pub2.png" alt="" class="pub-img mb-2" />
        <div>
          <p class="font-bold text-grey text-lg mb-1">June 14 2021</p>
          <p class="font-bold text-darkblue text-2xl line-height-1">
            How our foundation has touched lives
          </p>
          <p class="font-bold text-hibiscus text-lg">By Uju Okunowo</p>
          <p>
            It was and still the dream of Anchor Foundation to reach out and
            touch more lives, helping the helpless and giving hope to the
            hopelesss.
          </p>
          <button class="btn btn-md btn-aqua" routerLink="/article/{{ item }}">
            Read More
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer class="mt-5"></app-footer>
