import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-floating-arrow',
  templateUrl: './floating-arrow.component.html',
  styleUrls: ['./floating-arrow.component.scss'],
})
export class FloatingArrowComponent implements OnInit {
  showArrow: Boolean = true;
  constructor() {}

  ngOnInit(): void {}
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    if (window.scrollY == 0) {
      this.showArrow = false;
    } else {
      this.showArrow = true;
    }
  }

  scrollTop() {
    window.scroll(0, 0);
  }
}
