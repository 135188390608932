import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { MediaComponent } from './pages/media/media.component';
import { ArticleComponent } from './pages/article/article.component';
import { ArticleDetailsComponent } from './pages/article-details/article-details.component';
import { MissionComponent } from './pages/mission/mission.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import { RecentArticleComponent } from './component/recent-article/recent-article.component';
import { PopularArticleComponent } from './component/popular-article/popular-article.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { GetInvlovedComponent } from './component/get-invloved/get-invloved.component';
import { FloatingArrowComponent } from './component/floating-arrow/floating-arrow.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    MediaComponent,
    ArticleComponent,
    ArticleDetailsComponent,
    MissionComponent,
    NavbarComponent,
    FooterComponent,
    RecentArticleComponent,
    PopularArticleComponent,
    SidebarComponent,
    GetInvlovedComponent,
    FloatingArrowComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
