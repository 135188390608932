import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isOpen: Boolean = false;
  @Input() darkMode: Boolean;
  constructor() {
    this.darkMode = false;
  }

  ngOnInit(): void {
  }
  sidebarToggle() {
    this.isOpen = !this.isOpen;
  }
}
