<!-- Modal -->
<div
  class="modal fade"
  id="getinvolved"
  tabindex="-1"
  aria-labelledby="getinvolvedLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="d-flex align-items-center justify-content-between head p-4">
        <h5 class="text-xl font-bold text-center">
          Get Involve today to Keep Reaching Out to The Needy
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          Whether you choose to donate to charities supporting people living in
          poverty, advocating to protect the environment, or addressing other
          global or local problems, charities need your help to continue their
          selfless initiatives, but giving can feel just as good as receiving!
        </p>
        <hr class="my-5" />
        <form class="pb-5">
          <p class="font-bold text-lg">Your Information</p>
          <div class="row mb-4">
            <div class="col-lg-3 col-md-3 col-12">
              <label>Full Name <span class="text-red">*</span></label>
            </div>
            <div class="col-lg-6 col-md-9 col-12 me-auto">
              <input type="text" placeholder="eg. John" class="form-control" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-3 col-md-3 col-12">
              <label>Phone Number</label>
            </div>
            <div class="col-lg-6 col-md-9 col-12 me-auto">
              <input
                type="text"
                placeholder="Enter your active line"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-lg-3 col-md-3 col-12">
              <label>Email <span class="text-red">*</span></label>
            </div>
            <div class="col-lg-6 col-md-9 col-12 me-auto">
              <input
                type="text"
                placeholder="Enter your email address"
                class="form-control"
              />
              <div class="d-flex align-items-center mt-1">
                <input type="checkbox" class="me-2" id="contact-me" />
                <label for="contact-me" class="font-medium text-sm click"
                  >Contact me in the future</label
                >
              </div>
            </div>
          </div>
          <hr class="my-5" />
          <p class="font-bold text-lg">How do you want to get involved?</p>
          <div class="d-flex align-items-center mb-3">
            <input type="checkbox" class="me-2" id="Volunteering" />
            <label for="Volunteering" class="font-medium text-sm mb-0 click"
              >Volunteering</label
            >
          </div>
          <div class="d-flex align-items-center mb-3">
            <input type="checkbox" class="me-2" id="Gifts" />
            <label for="Gifts" class="font-medium text-sm mb-0 click"
              >Gifts</label
            >
          </div>
          <div class="d-flex align-items-center mb-3">
            <input type="checkbox" class="me-2" id="CashDonation" />
            <label for="CashDonation" class="font-medium text-sm mb-0 click"
              >Cash Donation</label
            >
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-10">
              <input
                type="text"
                placeholder="Specify Amount"
                class="form-control mb-3"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mb-3">
            <input
              type="radio"
              class="me-2"
              name="donation-type"
              id="One-off-donation"
            />
            <label for="One-off-donation" class="font-medium text-sm mb-0 click"
              >One-off donation</label
            >
          </div>
          <div class="d-flex align-items-center mb-3">
            <input
              type="radio"
              class="me-2"
              name="donation-type"
              id="As-often-as-desired"
            />
            <label
              for="As-often-as-desired"
              class="font-medium text-sm mb-0 click"
              >As often as desired</label
            >
          </div>
          <div class="d-flex align-items-center mb-3">
            <input
              type="radio"
              class="me-2"
              name="donation-type"
              id="Monthly"
            />
            <label for="Monthly" class="font-medium text-sm mb-0 click"
              >Monthly</label
            >
          </div>
          <div class="d-flex align-items-center mb-3">
            <input
              type="radio"
              class="me-2"
              name="donation-type"
              id="Quartely"
            />
            <label for="Quartely" class="font-medium text-sm mb-0 click"
              >Quartely</label
            >
          </div>
          <div class="d-flex align-items-center mb-3">
            <input type="radio" class="me-2" name="donation-type" id="Yearly" />
            <label for="Yearly" class="font-medium text-sm mb-0 click"
              >Yearly</label
            >
          </div>

          <button class="btn btn-md btn-purple mt-4" data-bs-dismiss="modal">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
