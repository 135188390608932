import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {
  lists = [1, 2, 3, 4, 5, 6];
  constructor() {}

  ngOnInit(): void {}
}
