<header>
  <div class="container">
    <app-navbar></app-navbar>
    <app-sidebar></app-sidebar>
    <div>
      <h2 class="font-bold">Media and Events</h2>
    </div>
  </div>
</header>
<app-floating-arrow></app-floating-arrow>
<section class="wrapper">
  <div class="container">
    <p class="text-center text-lg my-5">
      Here you will find Anchor foundation latest press release. For media
      assets high-resolution photos <br />
      and interviews, please
      <a href="/contact" class="text-aqua">contact us</a>.
    </p>
    <div class="row">
      <div
        class="col-lg-4 col-md-6 col-sm-11 col-12 mb-5"
        *ngFor="let item of lists"
      >
        <img src="../../../assets/img/pub1.png" alt="" class="pub-img mb-2" />
        <div class="row">
          <div class="col-lg-10 col-md-11 col-12">
            <p class="text-grey mb-2">August 19, 2021</p>
            <p class="font-bold text-darkblue text-xl line-height-1">
              How our foundation has touched lives
            </p>
            <div
              class="d-flex align-items-center click text-aqua font-medium"
              routerLink="/article/{{ item }}"
            >
              <span class="me-1">Read More</span>
              <span><span class="iconify" data-icon="fa-solid:angle-right"></span> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-5">
      <button class="btn btn-purple btn-md">Load More</button>
    </div>
  </div>
</section>
<app-footer class="mt-5"></app-footer>
