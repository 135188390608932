<header>
  <div class="container">
    <app-navbar></app-navbar>
    <app-sidebar></app-sidebar>
    <div>
      <h2 class="font-bold">Contact Us</h2>
      <div class="row">
        <div class="col-lg-9 col-md-10 col-sm-11 col-12 mx-auto">
          <p>We are always here for you. How can we be of help?</p>
        </div>
      </div>
    </div>
  </div>
</header>
<app-floating-arrow></app-floating-arrow>
<section class="wrapper">
  <div class="container">
    <div class="row py-5">
      <div class="col-lg-5 col-md-6 col-sm-12 mb-2">
        <form>
          <div class="form-group mb-4">
            <label>Full Name*</label>
            <input type="text" placeholder="eg. John" class="form-control" />
          </div>
          <div class="form-group mb-4">
            <label>Email*</label>
            <input
              type="text"
              placeholder="eg. Example@email.com"
              class="form-control"
            />
          </div>
          <div class="form-group mb-4">
            <label>Upload Document</label>
            <input
              type="file"
              accept="application/msword,application/pdf,text/plain,.doc,.docx"
              class="d-none"
              id="document"
            />
            <label class="file-picker mb-4" for="document">
              <span>Supported File format PDF, DOC</span>
              <span class="browse-btn"> Browse </span>
            </label>
          </div>
          <div class="form-group mb-4">
            <label>Message Type*</label>
            <input type="text" placeholder="eg. John" class="form-control" />
          </div>
          <div class="form-group mb-4">
            <label>Message Type*</label>
            <select class="form-control">
              <option value="">Submit an Article</option>
              <option value="">Submit an Article</option>
              <option value="">Submit an Article</option>
            </select>
          </div>
          <div class="form-group mb-4">
            <label>Telephone*</label>
            <input type="text" placeholder="eg. John" class="form-control" />
          </div>
          <div class="form-group mb-4">
            <label>Message*</label>
            <textarea
              rows="7"
              class="form-control"
              placeholder="Enter message"
            ></textarea>
          </div>
          <div class="mb-4">
            <button class="btn btn-purple btn-md">Submit</button>
          </div>
        </form>
      </div>
      <div class="col-lg-5 col-md-6 col-sm-12 mb-2 text-lg ms-auto">
        <p>We are always here for you. How can we be of help?</p>
        <p>You can also reach us using the contact information below.</p>
        <div class="d-flex align-items-center mb-2">
          <div class="me-2">
            <span class="iconify" data-icon="ph:at-bold"></span>
          </div>
          <a
            href="mailto:life@anchorfoundations.org"
            class="text-hibiscus font-medium"
          >
            life@anchorfoundations.org
          </a>
        </div>
        <div class="d-flex align-items-center mb-2">
          <div class="me-2">
            <span
              class="iconify"
              data-icon="clarity:phone-handset-solid"
            ></span>
          </div>
          <a href="tel:+234(0)8094642222" class="text-hibiscus font-medium"
            >+234(0)809 464 2222</a
          >
        </div>
        <div class="d-flex social-link">
          <a href="#" target="_blanc" class="text-2xl me-3">
            <span class="iconify" data-icon="jam:twitter-circle"></span>
          </a>
          <a href="#" target="_blanc" class="text-2xl me-3">
            <span class="iconify" data-icon="jam:linkedin-circle"></span>
          </a>
          <a href="#" target="_blanc" class="text-2xl me-3">
            <span class="iconify" data-icon="iconoir:telegram-circled"></span>
          </a>
          <a href="#" target="_blanc" class="text-2xl">
            <span class="iconify" data-icon="jam:facebook-circle"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
