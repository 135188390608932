<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-2 mb-4 order-lg-1 order-1">
        <img src="../assets/img/Anchor Foundation variant2-white.png" alt="" />
      </div>
      <div class="col-lg-5 order-lg-2 order-3">
        <div class="row">
          <div class="col-6 mb-3">
            <h3 class="font-bold text-lg">Navigation</h3>
            <p><a href="#">General</a></p>
            <p><a href="#">Godliness</a></p>
            <p><a href="#">Life Experience</a></p>
            <p><a href="#">Poetry</a></p>
            <p><a href="#">Prophesy</a></p>
            <p><a href="#">Salvation</a></p>
            <p><a href="#">Worship</a></p>
          </div>
          <div class="col-6 mb-3">
            <h3 class="font-bold text-lg">Recent Post</h3>
            <p><a href="#">General</a></p>
            <p><a href="#">Godliness</a></p>
            <p><a href="#">Life Experience</a></p>
          </div>
        </div>
      </div>
      <div class="col-lg-5 order-lg-3 order-2">
        <div class="row">
          <div class="col-lg-6 col-6 mb-3">
            <h3 class="font-bold text-lg">Contact</h3>
            <p>
              Email: <br />
              <a
                href="mailto:Life@anchorfoundations.org"
                class="text-green text-break"
              >
                Life@anchorfoundations.org</a
              >
            </p>
            <p>
              Telephone:
              <br />
              <a href="tel:+234(0)809 464 2222" class="text-green text-break">
                +234(0)809 464 2222</a
              >
            </p>
          </div>

          <div class="col-lg-6 col-6 mb-3">
            <h3 class="font-bold text-lg">About Anchor</h3>
            <p>Not-for-profit Christian charity organization and ministry.</p>
            <div class="d-flex">
              <a href="#" class="text-xl me-3">
                <span
                  class="iconify"
                  data-icon="ant-design:twitter-circle-filled"
                ></span>
              </a>
              <a href="#" class="text-xl me-3">
                <span
                  class="iconify"
                  data-icon="entypo-social:linkedin-with-circle"
                ></span>
              </a>
              <a href="#" class="text-xl me-3">
                <span class="iconify" data-icon="fontisto:telegram"></span>
              </a>
              <a href="#" class="text-xl">
                <span class="iconify" data-icon="cib:facebook"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
