import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recent-article',
  templateUrl: './recent-article.component.html',
  styleUrls: ['./recent-article.component.scss'],
})
export class RecentArticleComponent implements OnInit {
  lists = [1, 2, 3, 4];

  constructor() {}

  ngOnInit(): void {}
}
