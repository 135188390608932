<div
  class="{{ isOpen ? 'overlay-isOpen sidebar-overlay' : 'sidebar-overlay' }}"
>
  <div class="close" (click)="sidebarToggle()">
    <span
      class="iconify"
      data-icon="clarity:times-line"
      data-inline="false"
    ></span>
  </div>
</div>

<div
  class="{{
    isOpen ? 'navbar-toggler menu-icon d-none' : 'navbar-toggler menu-icon'
  }}"
  (click)="sidebarToggle()"
>
  <span
    class="iconify"
    data-icon="grommet-icons:menu"
    data-inline="false"
  ></span>
</div>
<div class="nav-logo-2">
  <a routerLink="/">
    <img src="../../../assets/img/logo-dark.png" alt="" *ngIf="darkMode" />
    
    <img
    src="../../../assets/img/Anchor Foundation variant2-white.png"
    alt=""
    *ngIf="!darkMode"
  />
  </a>
</div>
<section class="{{ isOpen ? 'sidebar-isOpen sidebar' : 'sidebar' }}">
  <div class="sidebar-wrapper">
    <div class="side-logo">
      <a routerLink="/">
        <img src="../../../assets/img/logo-dark.png" alt="" />
      </a>
    </div>
    <div class="mt-5">
      <a
        class="side-item"
        (click)="sidebarToggle()"
        routerLink="/"
        routerLinkActive="active"
      >
        Home
      </a>

      <a class="side-item" (click)="sidebarToggle()" href="#about">
        About Anchor
      </a>

      <a
        class="side-item"
        (click)="sidebarToggle()"
        routerLink="/mission"
        routerLinkActive="active"
      >
        Missions
      </a>
      <a
        class="side-item"
        (click)="sidebarToggle()"
        routerLink="/media"
        routerLinkActive="active"
        >Media & Events
      </a>

      <a
        class="side-item"
        (click)="sidebarToggle()"
        routerLink="/article"
        routerLinkActive="active"
        >Publications
      </a>

      <a
        class="side-item"
        (click)="sidebarToggle()"
        routerLink="/contact"
        routerLinkActive="active"
        >Contact
      </a>
    </div>
  </div>
</section>
