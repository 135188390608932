<header>
  <div class="container">
    <app-navbar></app-navbar>
    <app-sidebar></app-sidebar>
    <div>
      <h2 class="font-bold">Walking in God’s love towards humanity</h2>
      <div class="row">
        <div class="col-lg-9 col-md-10 col-sm-11 col-12 mx-auto">
          <p>
            Anchor Foundation is a not-for-profit Christian charity organization
            with strong foundation on the Word of God, to render charity works
            to people through gifts and social community services.
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-aqua me-3" routerLink="/mission">
          See Missions
        </button>
        <button
          class="btn btn-green"
          data-bs-toggle="modal"
          data-bs-target="#getinvolved"
        >
          Get Involved
        </button>
      </div>
    </div>
  </div>
</header>

<app-get-invloved></app-get-invloved>
<app-floating-arrow></app-floating-arrow>
<section class="about-section pt-5 pb-4 text-darkblue" id="about">
  <div class="container">
    <div class="text-center mb-5">
      <h2 class="font-bold">About Anchor Foundation</h2>
      <p>
        Anchor Foundation is a not-for-profit Christian charity organization
        with strong foundation on the Word of God to render charity works to
        people through gifts, social community services and ministry of the
        Word. <br />
        <br />

        That through faith in God’s Words and manifestation of the Holy Spirit,
        people shall have a lifetime experience with God and be filled with the
        joy of the Lord in their daily living. <br /><br />

        That we may impart faith, hope and love of God in the hearts of people
        as we advance the purposes of God to communities.
      </p>
    </div>
    <div class="row">
      <div class="ms-auto col-lg-4 com-md-6 col-sm-6 col-12 mb-2">
        <div class="bg-white py-5 px-3 border-radius">
          <h5 class="font-medium">Romans 5:5</h5>
          <p>
            The Word of God is our anchor as the Father has bestowed to us a
            living hope through our Lord Jesus Christ; and given us an
            inheritance that is incorruptible, undefiled and unfading. While on
            this earth, we can walk in faith, hope and love, because the love of
            God has been poured out in our hearts by the Holy Spirit who was
            given to us.
          </p>
        </div>
      </div>
      <div class="me-auto col-lg-4 com-md-6 col-sm-6 col-12 mb-2">
        <div class="bg-white py-5 px-3 border-radius">
          <h5 class="font-medium">Romans 5:5</h5>
          <p>
            The Word of God is our anchor as the Father has bestowed to us a
            living hope through our Lord Jesus Christ; and given us an
            inheritance that is incorruptible, undefiled and unfading. While on
            this earth, we can walk in faith, hope and love, because the love of
            God has been poured out in our hearts by the Holy Spirit who was
            given to us.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="vision-section py-5">
  <div class="container">
    <div class="text-center mb-4">
      <h2 class="font-bold">Vision</h2>
      <p>
        To make known the faith, hope and love of God through charity, the Word
        of God and the Holy Spirit.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-5 col-md-5 col-12 mb-2">
        <img src="../assets/img/Rectangle 17.svg" alt="" />
      </div>
      <div class="col-lg-6 col-md-7 col-12 mb-2">
        <h3 class="font-bold text-darkblue">Beliefs</h3>
        <p>
          Anchor Foundation trustees, staff, volunteers and partners believe as
          follows:
        </p>
        <ul>
          <li>
            That Jesus Christ has come in the flesh and made a propitiation for
            sins of mankind through his death and resurrection
          </li>
          <li>
            That whosoever believes in Him should not perish but have an
            everlasting life
          </li>
          <li>
            That the Father sent the Holy Spirit to believers a Helper, a
            Counselor to guide us into all truth
          </li>
          <li>
            That we should demonstrate the love of God to people around us
          </li>
          <li>
            That we must have a clear conscience without offense toward God and
            men and women, boys and girls.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="principles-section py-5">
  <div class="container">
    <h2 class="font-bold text-center mb-4">Principles</h2>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12 mb-2">
        <div class="mb-4">
          <h4 class="font-bold text-aqua">Godliness</h4>
          <p>
            To do everything in accordance with the Word of God. We embrace
            godliness which is profitable for all things, having the promise of
            the present life and that which is to come.
            <span class="text-hibiscus">1 Timothy 4:8.</span>
          </p>
        </div>
        <div class="mb-4">
          <h4 class="font-bold text-aqua">Love</h4>
          <p>
            To walk in God’s love towards all humanity, especially our
            stakeholders, from a pure heart and a good conscience and a sincere
            faith; allowing the love of God shed abroad in our hearts to
            influence what and how we operate both as individuals and corporate.
            <span class="text-hibiscus">1John 13:34-35</span>
          </p>
        </div>
        <div class="mb-4">
          <h4 class="font-bold text-aqua">Faith</h4>
          <p>
            To trust, rely and walk on the Word of God as our only source and
            evidence of hope. Faith works through love. In doing what we do, we
            will stir up love and good works in one another.

            <span class="text-hibiscus">Hebrews 10:23-24</span>
          </p>
        </div>
        <div class="mb-4">
          <h4 class="font-bold text-aqua">Honesty and transperency</h4>
          <p>
            conduct our affairs and operations with utmost transparency, honesty
            and accountability to our partners, staff, volunteers, donors and
            beneficiaries; not walking in craftiness nor adulterating the
            purposes of the foundation or Word of God, but by manifesting the
            truth and commending ourselves to every man and woman’s conscience
            in the sight of God.
            <span class="text-hibiscus">2 Corinthians 4:2.</span>
          </p>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-12 mb-2 d-md-block d-none">
        <img src="../assets/img/principle.svg" alt="" class="w-100 h-100" />
      </div>
    </div>
  </div>
</section>
<section class="pb-5">
  <div class="mission-group pt-5">
    <div class="container">
      <div class="text-center">
        <h3 class="font-bold text-white">Mission Groups</h3>
        <p class="text-white">
          We will reach out through our operations to mission groups such as:
        </p>
      </div>
      <div class="group-wrapper">
        <div class="row">
          <div class="col-lg-4 col-md-5 col-6 mb-2">
            <div class="group font-bold one">The orphans</div>
          </div>
          <div class="col-lg-4 col-md-5 col-6 mb-2">
            <div class="group font-bold two">The unsaved</div>
          </div>
          <div class="col-lg-4 col-md-5 col-6 mb-2">
            <div class="group font-bold three">The sick</div>
          </div>
        </div>
      </div>
      <section class="mission-work py-5">
        <div class="text-center">
          <h3 class="font-bold text-hibiscus">Mission Work</h3>
          <p class="text-darkblue">
            Giving helping hands with love to the community. Praying and
            believing with faith that their spirit are lifted with love and
            happiness.
          </p>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-6 col-12 mb-2">
            <div class="group one p-4">
              <p class="font-medium">
                Vacations and trip on special days for the less priviledged
              </p>
              <p class="text-hibiscus font-bold">READ MORE</p>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12 mb-2">
            <div class="group two p-4">
              <p class="font-medium">
                Counselling and prayers for those low in spirits, encouraging
                them in the word of God.
              </p>
              <p class="text-hibiscus font-bold">READ MORE</p>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12 mb-2">
            <div class="group three p-4">
              <p class="font-medium">
                Community service by helping old and those with disabilities
                clean their homes and surroundings.
              </p>
              <p class="text-hibiscus font-bold">READ MORE</p>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12 mb-2">
            <div class="group four p-4">
              <p class="font-medium">Financial Help to those in need</p>
              <p class="text-hibiscus font-bold">READ MORE</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="text-center py-5 container">
      <h2 class="font-bold text-hibiscus">Faculties within Anchor</h2>
      <p style="font-size: 1.2rem" class="mb-4">
        These are the functional departments that will synergistically work
        together to deliver on the vision and objectives of the foundation.
      </p>
      <div class="row">
        <div class="col-lg-9 col-md-10 col-12 mx-auto">
          <img
            src="../assets/img/Anchor_Faculties 1.svg"
            alt=""
            class="w-100"
          />
        </div>
      </div>
    </section>
    <section class="publication-wrapper container py-5">
      <div class="text-center">
        <p class="text-grey text-lg font-medium">
          Highlights of events and Inspiration
        </p>
        <p class="text-xl font-bold text-darkblue">Publication</p>
      </div>
      <div class="row">
        <div class="col-md-6 col-12 mb-2">
          <div class="pub-card">
            <img src="../assets/img/pub1.svg" alt="" class="mb-3" />
            <h6 class="text-sm text-hibiscus font-medium">INSPIRE ME</h6>
            <p class="text-lg text-darkblue">
              It is a Holy Ghost inspired writings on different topics to
              inspire faith and I can-do attitude in the readers
            </p>
            <p class="text-lg">2 hours ago</p>
          </div>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <div class="pub-card">
            <img src="../assets/img/pub2.svg" alt="" class="mb-3" />
            <h6 class="text-sm text-hibiscus font-medium">LIFE EXPERIENCES</h6>
            <p class="text-lg text-darkblue">
              It is a Holy Ghost inspired writings on different topics to
              inspire faith and I can-do attitude in the readers
            </p>
            <p class="text-lg">2 hours ago</p>
          </div>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <div class="pub-card">
            <img src="../assets/img/pub2.svg" alt="" class="mb-3" />
            <h6 class="text-sm text-hibiscus font-medium">LIFE EXPERIENCES</h6>
            <p class="text-lg text-darkblue">
              It is a Holy Ghost inspired writings on different topics to
              inspire faith and I can-do attitude in the readers
            </p>
            <p class="text-lg">2 hours ago</p>
          </div>
        </div>
        <div class="col-md-6 col-12 mb-2">
          <div class="pub-card">
            <img src="../assets/img/pub2.svg" alt="" class="mb-3" />
            <h6 class="text-sm text-hibiscus font-medium">LIFE EXPERIENCES</h6>
            <p class="text-lg text-darkblue">
              It is a Holy Ghost inspired writings on different topics to
              inspire faith and I can-do attitude in the readers
            </p>
            <p class="text-lg">2 hours ago</p>
          </div>
        </div>
      </div>
      <div class="text-center my-5">
        <button class="btn btn-aqua btn-md">Read More</button>
      </div>
    </section>
    <section class="recent-event-wrapper py-5">
      <div class="bg-aqua pt-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12 mb-2">
              <img src="../assets/img/touch.png" alt="" class="mb-2 w-100" />
              <h3 class="font-bold text-darkblue ps-3 b-l">
                How our foundation has touched lives
              </h3>
              <div class="ps-3">
                <p>
                  It was and still the dream of Anchor foundation to reach out
                  and touch more lives, helping the helpless and giving hope to
                  the hopelesss.
                </p>
                <button class="btn btn-aqua btn-md">Read More</button>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 mb-2">
              <h3 class="text-white font-bold text-xl">Recent Events</h3>
              <div class="bg-white border-radius p-4">
                <div>
                  <h5 class="text-darkblue font-medium">
                    How our foundation has touched lives
                  </h5>
                  <p>
                    It was and still the dream of Anchor foundation to reach out
                    and touch more lives, helping the helpless and giving hope
                    to the hopelesss.
                  </p>
                </div>
                <hr />
                <div>
                  <h5 class="text-darkblue font-medium">
                    How our foundation has touched lives
                  </h5>
                  <p>
                    It was and still the dream of Anchor foundation to reach out
                    and touch more lives, helping the helpless and giving hope
                    to the hopelesss.
                  </p>
                </div>
                <hr />
                <div>
                  <h5 class="text-darkblue font-medium">
                    How our foundation has touched lives
                  </h5>
                  <p>
                    It was and still the dream of Anchor foundation to reach out
                    and touch more lives, helping the helpless and giving hope
                    to the hopelesss.
                  </p>
                </div>
                <hr />
                <div class="text-hibiscus click font-medium mt-5">
                  <span class="me-2">See More Events</span>
                  <span class="iconify" data-icon="clarity:play-solid"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <app-footer></app-footer>
        </div>
      </div>
    </section>
  </div>
</section>
