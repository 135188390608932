import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() darkMode: Boolean;
  constructor() {
    this.darkMode = false;
  }

  ngOnInit(): void {}
  @HostListener('window:scroll', ['$event'])

  scrollFunction = () => {
    if (window.scrollY > 150) {
      document.querySelector('.navbar')?.classList.add('fixed-top');
    } else {
      document.querySelector('.navbar')?.classList.remove('fixed-top');
    }
  };
}
