import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popular-article',
  templateUrl: './popular-article.component.html',
  styleUrls: ['./popular-article.component.scss']
})
export class PopularArticleComponent implements OnInit {
  lists = [1, 2, 3, 4];

  constructor() { }

  ngOnInit(): void {
  }

}
