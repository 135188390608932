<nav
  class="navbar navbar-expand-lg {{ darkMode ? 'darkMode' : '' }}"
  (scroll)="scrollFunction()"
>
  <div class="container-fluid">
    <a class="navbar-brand" href="/">
      <img src="../../../assets/img/logo-dark.png" alt="" *ngIf="darkMode" />
      <img
        src="../../../assets/img/Anchor Foundation variant2-white.png"
        alt=""
        *ngIf="!darkMode"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="iconify" data-icon="dashicons:menu-alt3"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav ms-auto">
        <a
          class="nav-link {{ darkMode ? 'darkMode' : '' }} mr-b"
          routerLink="/"
          routerLinkActive="active"
          >Home</a
        >
        <a class="nav-link {{ darkMode ? 'darkMode' : '' }} mr-b" href="#about"
          >About Anchor</a
        >
        <a
          class="nav-link {{ darkMode ? 'darkMode' : '' }} mr-b"
          routerLink="/mission"
          routerLinkActive="active"
          >Missions</a
        >
        <a
          class="nav-link {{ darkMode ? 'darkMode' : '' }} mr-b"
          routerLink="/media"
          routerLinkActive="active"
          >Media & Events</a
        >
        <a
          class="nav-link {{ darkMode ? 'darkMode' : '' }} mr-b"
          routerLink="/article"
          routerLinkActive="active"
          >Publications</a
        >
        <a
          class="nav-link {{ darkMode ? 'darkMode' : '' }}"
          routerLink="/contact"
          routerLinkActive="active"
          >Contact</a
        >
      </div>
    </div>
  </div>
</nav>
