<header>
  <div class="container">
    <app-navbar></app-navbar>
    <app-sidebar></app-sidebar>
    <div>
      <h2 class="font-bold">Mission Works</h2>
    </div>
  </div>
</header>
<app-floating-arrow></app-floating-arrow>
<section class="wrapper">
  <div class="container">
    <p class="text-center text-lg my-5">
      Here you will find Anchor foundation latest press release. For media
      assets high-resolution photos <br />
      and interviews, please
      <a href="/contact" class="text-aqua">contact us</a>.
    </p>
    <div class="row">
      <div 
        class="col-lg-4 col-md-6 col-sm-11 col-12 mb-5"
        *ngFor="let item of lists"
      >
        <div class="group p-4">
          <p class="font-medium">
            Community service by helping old and those with disabilities clean
            their homes and surroundings.
          </p>
          <p class="text-hibiscus font-bold">READ MORE</p>
        </div>
      </div>
    </div>
    <div class="text-center my-5">
      <button class="btn btn-purple btn-md">Load More</button>
    </div>
  </div>
</section>
<app-footer class="mt-5"></app-footer>
